const CHAT_URI = process.env.REACT_APP_CHAT_URI

import { useEffect, useState } from 'react';

/**
 *
 * @param isAuthorization
 */
export const useOGChatWidget = (isAuthorization: boolean) => {
  const [isChatLoad, setChatLoad] = useState(false);

  /**
   *
   * @param value
   */
  const onChangeHasUnread = (value: boolean) => {
    const icoFavicon = document.getElementById('ico-favicon') as HTMLLinkElement;
    const svgFavicon = document.getElementById('svg-favicon') as HTMLLinkElement;
    const data = value ? 'unread_' : '';
    const ico = `/${data}favicon.ico`;
    const svg = `/${data}favicon.svg`;
    if (icoFavicon) icoFavicon.href = ico;
    if (svgFavicon) svgFavicon.href = svg;
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = CHAT_URI;
    script.onload = () => setChatLoad(true);
    script.onerror = (e) => console.error('Script loading error:', e);
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script); // Удаляем скрипт при размонтировании компонента
    };
  }, []);

  useEffect(() => {
    if (isChatLoad) {
      const chatWidgetContainer = document.getElementById('chatWidgetContainer');
      if (chatWidgetContainer) {
        chatWidgetContainer?.remove();
      }
      const cont = document.createElement('div');
      cont.id = 'chatWidgetContainer';
      cont.style.position = 'fixed';
      cont.style.bottom = '0';
      cont.style.right = '0';
      cont.style.zIndex = '100';
      document.body.appendChild(cont);

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window?.ChatWidget?.startWidget({
        elementId: 'chatWidgetContainer',
        chatType: 'chat',
        isHide: isAuthorization,
        onChangeHasUnread,
        iconPlacement: {
          bottom: 20,
          right: 100,
          position: 'absolute',
        },
      });
    }
  }, [isChatLoad]);

  useEffect(() => {
    const isHide = isAuthorization;
    if (isChatLoad) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window?.ChatWidget?.updateWidgetConfig({
        onChangeHasUnread,
        isHide,
      });
    }

    if (isHide) {
      onChangeHasUnread(false);
    }
  }, [isAuthorization, isChatLoad]);
};

