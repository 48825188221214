import React from 'react';
import { Redirect, Route, RouteComponentProps } from 'react-router-dom';
import { getAccessToken, getRefreshToken } from 'common/token';

export const PrivateRoute: React.FC<{
    path: string;
    exact?: boolean;
    component?: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
}> = ({ path, component, exact }) => {
    const redirectPath = window.location.pathname;
    if (!(getAccessToken() || getRefreshToken())) {
        return <Redirect exact to={`/new/auth/login?path=${redirectPath.includes('new/auth') ? '/' : redirectPath}`} />;
    }

    return <Route exact={exact} path={path} component={component} />;
};
